import { render, staticRenderFns } from "./GptSetpointFeedback.vue?vue&type=template&id=0adff398&scoped=true&"
import script from "./GptSetpointFeedback.vue?vue&type=script&lang=ts&"
export * from "./GptSetpointFeedback.vue?vue&type=script&lang=ts&"
import style0 from "./GptSetpointFeedback.vue?vue&type=style&index=0&id=0adff398&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0adff398",
  null
  
)

export default component.exports